/* CSS Version 2.5 */

@font-face {
  font-family: "Azonix";
  src: url('./fonts/azonix.otf')
}

@font-face {
  font-family: "Consolas";
  src: url('./fonts/consola.ttf')
}

@font-face {
  font-family: "Tahoma";
  src: url('./fonts/consola.ttf')
}

@keyframes fadeIn {
  0% { opacity: 1.0; background-color: #020202; }
100% { opacity: 0.0; background-color: #020202;  }
}

@keyframes fadeOut {
  0% { opacity: 0.0; background-color: #020202;  }
100% { opacity: 1.0; background-color: #020202;  }
}

@keyframes growOut {
  0% { width:  0px; visibility: hidden;}
0.1% { width:  0px; visibility: visible;}
100% { width: 80px; }
}

@keyframes slideDown {
  0% { top: -500px; visibility: visible; }
100% { top: 0px;    }
}

a {
  text-decoration: none;
}

.bigPosterSize {
  width: 300px;
  height: 300px
}

.backToResults {
  font-family: "Azonix";
  color: #6c6c72;
  font-size: 10px;
  visibility: hidden;
}

.bodyStyle {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  color: black;
}

.bottomGradient {
  background: linear-gradient(to bottom, #323232 25%, #3F3F3F 60%, #1C1C1C 130%), linear-gradient(to top, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.25) 150%);
  padding: 0px;
  opacity: 0.75;
}

.borderNoSpacing {
  border-spacing: 0px
}

.borderSpacing2px {
  border-spacing: 2px;
}

.cellPadding0px {
  padding: 0px
}

.cellPadding5px {
  padding: 5px
}

.cellPadding10px {
  padding: 10px
}

.centerAlignedText {
  text-align: center;
  vertical-align: middle;
}

.centerAlignedTopText {
  text-align: center;
  vertical-align: top;
}

.centerAlignedBottomText {
  text-align: center;
  vertical-align: bottom;
}

.centeredAlignedTable {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.darkenedBackground {
  background-color: #00000080;
  fill-opacity: 1;
  opacity: 1;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.32, 25, 80);
}

.darkenedTableBackground {
  background-color: #00000080;
  fill-opacity: 1;
  opacity: 1;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(.32, 25, 80);
}

.full {
  width: 100%;
  height: 100%;
}

.fullDetailsPane {
  position: relative;
  width: 800px;
  top: 20%;
  box-shadow: 2px 2px rgba(255, 255, 255, 0.40);
}

.tableRoot {
  position: absolute;
  width: 100%;
  height: 100%;
}

.grayBorderCell {
  border-color: rgb(192, 192, 192);
  border-width: 1px;
  border-style: ridge
}

.inputGradient {
  background: linear-gradient(to bottom, #323232 25%, #3F3F3F 60%, #1C1C1C 130%), linear-gradient(to top, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.25) 150%);
  border-width: 0px;
}

.imageLoading {
  position: absolute;
  top: 46.5%;
  left: 40%;
  width: 20%;
  text-align: center;
  visibility: hidden;
}

.inputChoiceText {
  font-family: "Azonix";
  color: rgb(39, 13, 204);
  text-align: center;
  font-size: 15px;
  height: 24px;
  position: "inherit";
  top: 0px;
  left:50%;
}

.labelText {
  font-family: "Consolas";
  color: whitesmoke;
  font-size: 15px;
}

.labelTitleText {
  font-family: "Consolas";
  color: rgb(128, 128, 192);
  font-size: 15px;
}

.labelTitleSmallText {
  font-family: "Consolas";
  color: whitesmoke;
  font-size: 12px;
}

.labelTitleSmallTextBlue {
  font-family: "Consolas";
  color: rgb(128, 128, 192);
  font-size: 12px;
  text-shadow: rgba(0, 0, 0, 0.75) 1px 1px;
}


@keyframes fadeInModal {
  0% { opacity: 0.0; background-color: #000000; }
100% { opacity: 1.0; }
}

.modal {
  position: absolute;
  background-color: white;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.35);
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeInModal 50ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modal-overlay-visible {
  position: absolute;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  animation: slideDown 350ms;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modal-overlay-hidden {
  visibility: hidden;
}

.moviePlot {
  position: inherit;
  min-height: 100px;
  height: 100px;
  max-height: 125px;
  width: 100%;
  padding: 5px;
}

.movieDetailsHeight {
  height: 10%;
  padding: 0px;
}

.movieDetailsLabel {
  font-family: "Azonix";
  font-weight: 200;
  color: lightgray;
  text-align: center;
  font-size: 14px;
}

.movieDetailsText {
  font-family: "Azonix";
  font-weight: 100;
  color: lightyellow;
  text-align: center;
  font-size: 12px;
}

.moviePlotText {
  font-family: "Azonix";
  color: lightgray;
  text-align: center;
  font-size: 12px;
}

.movieTileWidth {
  width: 100%;
}

.movieTitleText {
  font-family: "Azonix";
  color: rgb(111, 111, 222);
  font-size: 23px;
  font-weight: 500;
}

.movieTitleLongText {
  font-family: "Azonix";
  color: rgb(111, 111, 222);
  font-size: 16px;
  font-weight: 500;
}

.pageBackground {
  background: linear-gradient(to right, #202020 25%, #3F3F3F 60%, #1C1C1C 130%), linear-gradient(to top, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.25) 1000%);
  background-blend-mode: multiply;
  background-color: #32323200;
}

.pageHeader {
  font-family: "Azonix";
  color: paleturquoise;
  font-size: 30px;
}

.posterStyle {
  text-align: center;
  vertical-align: middle;
  border-width: 1px;
  border-style: ridge;
  padding: 3px
}

.posterBorderCell {
  border-color: rgb(111, 111, 222);
  border-width: 1px;
  border-style: ridge;
  background: linear-gradient(to top, #323232 25%, #3F3F3F 60%, #1C1C1C 192%), linear-gradient(to top, rgba(255, 255, 255, 100) 50%, rgba(0, 0, 0, 0.75) 120%);
  background-blend-mode: multiply;
}

.posterDiv {
  height: 100%
}

.quarterWidth {
  width: 25%
}

.searchGradient {
  background: linear-gradient(to bottom, #323232 25%, #3F3F3F 60%, #1C1C1C 130%), linear-gradient(to top, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.25) 150%);
  border-width: 0px;
  opacity: 0.85;
}

.selectChoices {
  font-family: "Azonix";
  background-color: rgba(212, 212, 212, .15);
  color: black;
  font-size: 10px;
  width: 25px;
  height: 16px;
  position: relative;
  border-width: 1px;
  text-align: center;
  vertical-align: bottom;
  border-color: #030303;
  padding: 0px;
  top: -4px;
  left: 0px;
}

.selectedChoice {
  font-family: "Azonix";
  background-color: rgba(212, 212, 212, 1);
  color: black;
  font-size: 10px;
  width: 25px;
  height: 16px;
  position: relative;
  border-width: 1px;
  text-align: center;
  vertical-align: bottom;
  border-color: #e3e3e3;
  padding: 0px;
  top: -4px;
  left: 0px;
}

.smallPosterHeight {
  height: 60%
}

.smallPosterHeight {
  height: 200px
}

.starsRating {
  visibility: visible;
  animation: growOut 1000ms;
  animation-delay: 50ms;
}

.subLabelText {
  font-family: "Consolas";
  color: whitesmoke;
  font-size: 12px;
}

.submitButton {
  font-family: "Azonix";
  background-color: rgba(212, 212, 212, 1);
  color: black;
  font-size: 10px;
  width: 80px;
  height: 16px;
  position: relative;
  border-width: 1px;
  text-align: center;
  vertical-align: bottom;
  border-color: #e3e3e3;
  padding: 0px;
  top: -4px;
  left: 0px;
}

.submitButtonPage {
  font-family: "Azonix";
  font-size: 12px;
  visibility: visible;
  height: 15px;
  width: 65px;
  vertical-align: top;
}

.subTitleHeight {
  height: 30px;
  vertical-align: middle;
}

.tableHeader {
  color: darkslateblue;
  text-align: center;
  vertical-align: middle;
  border-width: 0px;
}

.tableHeaderText {
  font-family: "Azonix";
  color: rgb(242, 241, 250);
  font-size: 16px;
}

.tileContentText {
  font-family: "Azonix";
  color: rgba(255, 255, 255, 0.90);
  font-size: 12px;
  text-shadow: rgba(c2, c2, c2, 1) 1px 1px;
}

.titleGradient {
  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.25) 200%);
  background-blend-mode: multiply;
  vertical-align: top;
  padding: 0px;
  opacity: 0.85;
}

.titleHeight {
  height: 47px;
}

.titleHeader {
  font-family: "Tahoma";
  color: #6c6c72;
  font-size: 18px;
}

.tileDimensions {
  padding: 5px;
  width: 20%;
  min-width: 20px;
  max-width: 50px;
  max-height: 300px;
}

.tilesPane {
  visibility: visible;
  border-spacing: 2px;
  border-color: rgb(192, 192, 192);
  border-style: initial;
  margin-left: auto;
  margin-right: auto;
  width: 865px;
  z-index: 1000;
}

.whiteBorderCell {
  border-color: whitesmoke;
  border-width: 1px;
  border-style: ridge;
  background: linear-gradient(to bottom, #323232 25%, #3F3F3F 60%, #1C1C1C 130%), linear-gradient(to top, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.25) 150%);
  background-blend-mode: multiply;
}

.portalImage {
  position:absolute;
  width: 100%;
  z-index: -2;
}

.portalVideo {
  position:absolute;
  width: 100%;
  animation-delay: 1250ms;
  animation: fadeIn 250ms;
  z-index: -1;
}

/* Scaling Max Width 1280 */
@media only screen and (min-width: 1280px) {
  .portalImage,
  .portalVideo {
      top: 80px;
  }
}

/* Scaling Max Width 1366 */
@media only screen and (min-width: 1366px) {
  .portalImage,
  .portalVideo {
      top: 144px;
  }
}

/* Scaling Max Width 1920 */
@media only screen and (min-width: 1920px) {
  .portalImage,
  .portalVideo {
      top: 45px;
  }
}
.active {
    opacity: 0.75;
    width: 35px;
}

.active > .page-link, .page-link.active {
    background-color:  rgb(111, 111, 222);
    color: white;
    border: 0px;
    height: 31px;
}

.disabled
{
    background-color: rgba(0, 0, 0, 0.15);
    opacity: .2;
    height: 27px;
}

.pagination {
    border-width: 1px;
}

.pagination-font {
    font-family: 'Azonix';
    font-size: 12px;
}

.page-item {
    width: 45px;
}

.pagination-pane {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: 0px;
  }

.pagination-sm {
    margin: auto;
    padding-left: 90px;
}

.pagination > li > a
{
    background-color: rgba(0, 0, 0, 0.15);
    height: 31px;
    color: whitesmoke;
    text-align: center;
    border-width: 0px;
    text-shadow: rgba(0, 0, 0, 0.65) 1px 1px;
}

.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: rgba(255, 255, 255, 0.25);
    background-color: black;
    text-align: center;
    opacity: 0.35;
    fill-opacity: 1;
    transition-duration: 3s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}